import React, { useEffect } from 'react';
import { graphql, PageProps, Script } from 'gatsby';
import Section from '@components/Sections/Section';
import { NewTemplateQuery } from 'graphql-types';
import theme from '@theme/theme';
import {
  GroupTitle,
  TemplatePreviews,
} from '@components/Catalog/CatalogGroup/CatalogGroupStyled';
import TemplatePreview from '@components/Catalog/CatalogGroup/TemplatePreview/TemplatePreview';
import useSaveUtmParamsToLocalStorage from '@src/hooks/useSaveUtmParamsToLocalStorage';
import { INewTemplatePageContextProps } from '@contexts/interfaces';
import { HeaderFragmentWithVariant } from '@components/Layout/NavigationBar/interfaces';
import PageWrapper from './shared/PageWrapper';
import { SimilarTemplatesWrapper } from './NewTemplateStyled';
import { analyticsSnippet } from './analytics/analytics';
import trackPage from './analytics/trackPage';

const NewTemplate = ({
  data,
  pageContext,
}: PageProps<NewTemplateQuery, INewTemplatePageContextProps>) => {
  const filteredTemplates =
    data?.allTemplates?.edges?.filter((edge) => {
      const isDifferentTemplate = edge.node._id !== data?.newTemplate?._id;
      const sameCategory =
        edge.node.category.filter(
          (c) => c?._id === data?.newTemplate?.category[0]?._id
        ).length > 0;
      return sameCategory && isDifferentTemplate;
    }) ?? [];

  const currentCategoryName =
    data?.newTemplate?.category[0]?.categoryName?.localized;

  const similarThingsTitle =
    data?.newTemplate?.category[0]?.similarThingsTitle?.localized;

  useSaveUtmParamsToLocalStorage();
  useEffect(() => {
    trackPage({
      categorySlug: data.newTemplate?.catalog?.slug?.localized,
      pageSlug: data.newTemplate?.slug?.localized,
    });
  }, [
    data.newTemplate?.catalog?.slug?.localized,
    data.newTemplate?.slug?.localized,
  ]);

  return (
    <PageWrapper
      locale={pageContext.locale}
      navigationBar={
        data?.newTemplate?.catalog?.navigationBar as HeaderFragmentWithVariant
      }
      metadata={data?.newTemplate?.metadata}
      backgroundColor={theme.colors.gray.athensGrayLight}
      announcementId={
        data?.newTemplate?.catalog?.navigationBar?.announcement?._id
      }
      footerBar={data?.sanityFooterBar}
    >
      {data.newTemplate.content.map((content) => (
        <Section key={content._key} content={content} />
      ))}
      {filteredTemplates.length > 0 && (
        <SimilarTemplatesWrapper>
          <GroupTitle>
            {currentCategoryName
              ? `${
                  similarThingsTitle ?? ''
                } ${currentCategoryName.toUpperCase()}`
              : ''}
          </GroupTitle>
          <TemplatePreviews>
            {filteredTemplates.map((template) => (
              <TemplatePreview
                key={template.node._id}
                template={template.node}
              />
            ))}
          </TemplatePreviews>
        </SimilarTemplatesWrapper>
      )}
    </PageWrapper>
  );
};

export const Head = () => (
  <>
    <Script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: analyticsSnippet,
      }}
    />
    <Script
      async
      src="https://tag.clearbitscripts.com/v1/pk_54aa5d556dad4b9548cc7054eba8b2ca/tags.js"
      referrerPolicy="strict-origin-when-cross-origin"
    />
  </>
);
export const query = graphql`
  query NewTemplate(
    $id: String!
    $locale: String!
    $catalogId: String!
    $footerBarId: String!
  ) {
    sanityFooterBar(_id: { eq: $footerBarId }) {
      ...FooterBar
    }
    newTemplate: sanityNewTemplate(_id: { eq: $id }) {
      _id
      _key
      slug {
        localized(locale: $locale)
      }
      category {
        _id
        categoryName {
          localized(locale: $locale)
        }
        similarThingsTitle {
          localized(locale: $locale)
        }
      }
      highlighted
      catalog {
        slug {
          localized(locale: $locale)
        }
        navigationBar {
          ...Header
        }
      }
      title {
        localized(locale: $locale)
      }
      templateLink {
        localized(locale: $locale)
      }
      description {
        localized(locale: $locale)
      }
      content {
        ...ContentSections
      }
      metadata {
        ...SEO
      }
      tracking {
        ...tracking
      }
      carouselImages {
        ...ImageWithCompleteData
      }
      redirects
    }
    catalogCategory: allSanityCatalogCategory(
      filter: { catalog: { _id: { eq: $catalogId } } }
    ) {
      edges {
        node {
          slug {
            localized(locale: $locale)
          }
          _id
          _key
          categoryName {
            localized(locale: $locale)
          }
          iconBackgroundColor {
            hex
          }
          icon {
            ...ImageWithCompleteData
          }
        }
      }
    }
    allTemplates: allSanityNewTemplate(
      filter: { catalog: { _id: { eq: $catalogId } } }
    ) {
      edges {
        node {
          _id
          _key
          slug {
            localized(locale: $locale)
          }
          coverImageBgColor {
            hex
          }
          coverImage {
            ...ImageWithCompleteData
          }
          category {
            slug {
              localized(locale: $locale)
            }
            _id
            _key
            categoryName {
              localized(locale: $locale)
            }
            iconBackgroundColor {
              hex
            }
            icon {
              ...ImageWithCompleteData
            }
          }
          highlighted
          catalog {
            slug {
              localized(locale: $locale)
            }
            navigationBar {
              ...Header
            }
          }
          title {
            localized(locale: $locale)
          }
          description {
            localized(locale: $locale)
          }
        }
      }
    }
  }
`;

// ts-prune-ignore-next
export default NewTemplate;
