import { GroupTitle } from '@components/Catalog/CatalogGroup/CatalogGroupStyled';
import styled from 'styled-components';

export const SimilarTemplatesWrapper = styled.div`
  padding: 60px 120px;
  max-width: 1440px;
  margin: auto;
  ${({ theme }) => theme.BreakpointMobile`
    padding: 40px 0;

    ${GroupTitle} {
      padding-left: 25px;
    }
  `};
`;
